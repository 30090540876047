import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Text, Notification} from 'grommet';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../AxiosInstance';
import Breadcrumb from '../../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const AddAccount = () => {
    const elements = [{'label': 'Configurations', 'link': '/Settings'}, {'label': 'Nouveau compte', 'link': '/AddAccount'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box pad="medium" direction="column">
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Ajouter un compte</CardHeader>
                    <CardBody pad="small" height="medium" flex><AccountForm /></CardBody>
                </Card>
            </Box>
        </Box>  
    );
}

const AccountForm = () => {
    const [code, setCode]  = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        axiosInstance.post("/api/account/", {"nom": name, "code": code}).then((response) => {
            setMessage(response.data.message);
            setVisible(true);
            
        })
    }

    return (
        <Form onSubmit={(value) => handleSubmit()}> 
            {visible && (<Notification
                title="Infos"
                message={message}
                onClose={() => {setVisible(false)}}
                status = "warning"    
            />)}
            <FormField name="code" htmlFor="textinputCode" label="Code" margin="small" width="50%">
                <TextInput id="textinputCode" name="code"  required onChange={(event) => setCode(event.target.value)}/>
            </FormField>
            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Ajouter" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default AddAccount; 