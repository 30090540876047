import React from 'react';
import { Navigate } from 'react-router-dom';
import { Grommet, Box } from 'grommet';
import { theme } from '../components/theme';
import AppBar from '../components/Appbar';
import Navbar from '../components/Navbar';


function isLogin(){
    if(localStorage.getItem("token")){
        return true;
    }
    return false;
}

function PrivateRoute({ children, ...rest }) {
    let auth = isLogin();

    if(auth) {
        return (<Grommet theme={theme} full>
                    <Box fill>
                        <AppBar />
                        <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                            <Navbar />
                            {children}
                        </Box>  
                    </Box>
                </Grommet>        
                    );
    }
    else {
        return  <Navigate to="/login" />;
    }
}

export default PrivateRoute;