import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Select, Layer, FileInput, DateInput, Anchor, Text} from 'grommet';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../AxiosInstance';
import { TYPE_ELEMENT, TYPE_MEANS_OF_PAYMENT, INFLOW, OUTFLOW } from "../../Params";
import Breadcrumb from '../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const AddFunds = () => {
    let {type} = useParams();
    const [title, setTitle] = useState("");
    const [label, setLabel] = useState("");

    useEffect(() => {
        let value = []
        switch(parseInt(type)){
            case INFLOW:
                value = [{'label': 'Entrée de fond', 'link': '/Cashin'},{'label': "Ajout d'une entrée de fonds", 'link': '/AddFunds/'+type}]
                setLabel("Ajout d'une entrée de fonds");
                break;
            case OUTFLOW:
                value = [{'label': 'Sortie de fond', 'link': '/Cashout'},{'label': "Ajout d'une sortie de fonds", 'link': '/AddFunds/'+type}]
                setLabel("Ajout d'une sortie de fonds")
                break;
            default:
                value = "je suis perdu"
                break;
        }
        setTitle(<Breadcrumb elements={value}/>)
    }, [])
    
    return (
        <Box direction="column" fill>
            {title}
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>{label}</CardHeader>
                    <CardBody pad="small" height="medium" flex overflow="auto"><CashForm /></CardBody>
                </Card>    
            </Box>  
        </Box>     
    );
}

const CashForm = () => {
    const [date, setDate] = useState(new Date().toISOString()); 
    const [elements, setElements] = useState([]);
    const [payments, setPayments] = useState([])
    const [element, setElement] = useState([]);
    const [payment, setPayment] = useState([]);
    const [amount, setAmount] = useState([]);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState([]);
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const [files , setFiles] = useState([]);
    let {type} = useParams()
    const navigate = useNavigate();


    const handleDate = (value) => {
        setDate(value);
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('type', type);
        formData.append('element', element);
        formData.append('date', parseInt((new Date(date.split("T")[0]).getTime() / 1000).toFixed(0)));
        formData.append('means_of_payment', payment);
        formData.append('name',name);
        formData.append('amount', amount);
        formData.append('project', project)
        // let data = {type:type, element:element, means_of_payment:payment, name:name, amount:amount }
        for (let i = 0; i < files.length; i += 1) {
            formData.append(`files[${i}]`,files[i]);
        }
        formData.append("file_number", files.length);
        axiosInstance.post("/cash/cashfunds/", formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }}).then((response) => {
            setMessage(response.data.message);
            setVisible(true);
        })
    }

    const getElements = () => {
        axiosInstance.get("/cash/elements/?type="+TYPE_ELEMENT).then(result => {
            setElements(result.data);
        });
    }

    const getPayments = () => {
        axiosInstance.get("/cash/elements/?type="+TYPE_MEANS_OF_PAYMENT).then(result => {
            setPayments(result.data);
        });
    }

    const getProjects = () => {
        axiosInstance.get("/project/projects/").then(result => {
            setProjects(result.data);
        });
    }

    useEffect(() => {
        getElements();
        getPayments();
        getProjects();
    },[])

    return (
        <Form onSubmit={(value) => handleSubmit()}> 
            {visible && (
            <Layer
                onEsc={() => setVisible(false)}
                onClickOutside={() => setVisible(false)}
                margin="medium"
                style={{padding: "2%"}}
              >
              <Text>{message}</Text>
                <Box direction="row"  pad="xsmall" alignSelf="end" >
                  <Anchor label="Fermer" color="primary" align='start' onClick={() => setVisible(false)} />
                </Box>
              </Layer>
            
            )}
            <FormField name="date" htmlFor="textinputDate" label="Date" margin="small" width="50%">
                <DateInput value={date} id="textinputAmount" required name="date" onChange={(event) => handleDate(event.value)} format="dd/mm/yyyy"/>
            </FormField>
            <FormField name="name" htmlFor="textinputName" label="Objet" margin="small" width="50%">
                <TextInput id="textinputName" required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <FormField name="amount" htmlFor="textinputName" label="Montant" margin="small" width="50%">
                <TextInput id="textinputAmount" required name="amount" onChange={(event) => setAmount(event.target.value)} type="number"/>
            </FormField>
            <FormField name="elements" htmlFor="elements" label="Elements" width="50%" margin="small">
                <Select options={elements} onChange={({option}) => setElement(option.id) } labelKey="name" required/>
            </FormField>
            <FormField name="payment" htmlFor="payment" label="Moyen de Paiements" width="50%" margin="small">
                <Select options={payments} onChange={({option}) => setPayment(option.id) } labelKey="name" required/>
            </FormField>
            <FormField name="projects" htmlFor="projects" label="Projets" width="50%" margin="small">
                <Select options={projects} onChange={({option}) => setProject(option.id) } labelKey="name" required/>
            </FormField>
            <FormField name="file" htmlFor="textFile" label="Justificatif" margin="small" width="50%">
                <FileInput id="textFile" 
                    onChange={(event) => {setFiles(event.target.files)}}
                    multiple 
                    messages={{browse:"Ajouter", dropPromptMultiple:"Faites glisser les fichiers ici"}}/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Ajouter" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default AddFunds; 
