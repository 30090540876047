import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Select, Notification} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../AxiosInstance';
import Breadcrumb from '../../Custom/Breadcrumb';
import { TYPE_ELEMENT, TYPE_MEANS_OF_PAYMENT } from '../../../Params';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const AddElement = () => {
    let {type} = useParams();
    const [title, setTitle] = useState("");

    useEffect(() => {
        let value = []
        switch(parseInt(type)){
            case TYPE_ELEMENT:
                value = [{'label': 'Paramètres', 'link': '/Settings'},{'label': "Ajout d'un élément", 'link': '/AddElement/'+type}]
                break;
            case TYPE_MEANS_OF_PAYMENT:
                value = [{'label': 'Paramètres', 'link': '/Settings'},{'label': "Ajout d'un moyen de paiement", 'link': '/AddElement/'+type}]
                break;
            default:
                value = "je suis perdu"
                break;
        }
        setTitle(<Breadcrumb elements={value}/>)
    }, [])
    
    return (
        <Box direction="column" fill>
            {title}
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Ajout d'un élément</CardHeader>
                    <CardBody pad="small" height="medium" flex><ElementForm /></CardBody>
                </Card>    
            </Box>  
        </Box>    
    );
}

const ElementForm = () => {
   
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [histAccounts, sethistAccounts] = useState([]);
    const [account, setAccount] = useState(0);
    let {type} = useParams()
   
    const getAccounts = () => {
        axiosInstance.get("/api/account/").then((response) => {
            setAccounts(response.data);
            sethistAccounts(response.data);
        })
    }

    useEffect(() => {
        getAccounts();
    }, [])

    const handleSubmit = () => {
        axiosInstance.post("/cash/elements/", {"name": name, 'account': account, 'type': type}).then((response) => {
            setMessage(response.data.message);
            setVisible(true);
        })
    }

    const handleAccountSearch = (e) => {
        if(e){
            let search = e.toLowerCase();
            let result = accounts.filter(element => {
                 
                if (element.code.toLowerCase().includes(search) || element.nom.toLowerCase().includes(search)){
                    return element;
                }
            });
            setAccounts(result);
        }
        else{
            setAccounts(histAccounts)
        }
    }

    const getAccountName = (ac) => {
        return `${ac.code}-${ac.nom}`;
    }

    return (
        <Form onSubmit={(value) => handleSubmit()}> 
            {visible && (<Notification
                title="Infos"
                message={message}
                onClose={() => {setVisible(false)}}
                status = "warning"
            />)}
            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <FormField name="account" htmlFor="account" label="Compte" width="50%" margin="small">
                <Select 
                    options={accounts} 
                    onChange={({option}) => setAccount(option.id) } 
                    labelKey={(opt) => getAccountName(opt)} 
                    onSearch = {(e) => {handleAccountSearch(e)}}
                    required/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Ajouter" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default AddElement; 
