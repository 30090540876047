import React from 'react';
import { Card, CardHeader, CardBody } from "grommet";

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}

export const CustomCard = (props) => {
    const title = props.title;
    const content = props.content
    return(
        <Card fill background="light-1" style={styles}>
            <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>{title}</CardHeader>
            <CardBody pad="small" height="medium" flex overflow="auto">{content}</CardBody>
        </Card>
    )
}

export const BigCustomCard = (props) => {
    const title = props.title;
    const content = props.content
    return(
        <Card fill background="light-1" style={{marginTop: '2%', borderRadius:'0px'}}>
            <CardHeader pad="small" background="primary"  style={{fontWeight:"Bold"}}>{title}</CardHeader>
            <CardBody pad="small" height="medium" flex>{content}</CardBody>
        </Card>
    )
}