import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Login from "../components/Login"; 
import Register from '../components/Register';
import Main from "../components/Main"; 
import Cashout from '../components/cash/Cashout';
import PrivateRoute from './PrivateRoute';
import Cashin from '../components/cash/Cashin';
import Settings from '../components/settings/Settings';
import AddAccount from "../components/settings/Account/AddAccount";
import EditAccount from "../components/settings/Account/EditAccount";
import AddJournal from '../components/settings/Journal/AddJournal';
import EditJournal from '../components/settings/Journal/EditJournal';
import AddElement from '../components/settings/Elements/AddElement';
import EditElement from '../components/settings/Elements/EditElement';
import AddFunds from '../components/cash/AddFunds';
import EditFunds from '../components/cash/EditFunds';
import { Dettes, Stock, Creances, Amortization } from '../components/Evaluations/Evaluations';
import AddEvaluation from '../components/Evaluations/AddEvaluation';
import EditEvaluation from '../components/Evaluations/EditEvaluation';
import EditAccountConfig from '../components/settings/EditAccountConfig';
import File from '../components/File/File';
import Blotter from '../components/Blotter/Blotter';
import { ProtectedRoute } from './ProtectedRoute';
import AddProject from '../components/settings/Project/AddProject';
import EditProject from '../components/settings/Project/EditProject';

function Router() {
  return (
   
    <Routes>
      <Route path="/login" element={<Login/>}></Route>
      <Route path="/Register" element={<Register/>}></Route>
      <Route path="/Cashout" element={<PrivateRoute><Cashout /></PrivateRoute>}></Route>
      <Route path="/Cashin" element={<PrivateRoute><Cashin /></PrivateRoute>}></Route>
      <Route path="/Amortization" element={<PrivateRoute><Amortization /></PrivateRoute>}></Route>
      <Route path="/Stock" element={<PrivateRoute><Stock /></PrivateRoute>}></Route>
      <Route path="/Dettes" element={<PrivateRoute><Dettes /></PrivateRoute>}></Route>
      <Route path="/Creances" element={<PrivateRoute><Creances /></PrivateRoute>}></Route>
      <Route path="/Settings" element={<PrivateRoute><ProtectedRoute children={<Settings />} perms="view_configuration"/></PrivateRoute>}></Route>
      <Route path="/AddAccount" element={<PrivateRoute><AddAccount /></PrivateRoute>}></Route>
      <Route path="/EditAccount/:id" element={<PrivateRoute><EditAccount /></PrivateRoute>}></Route>
      <Route path="/AddJournal" element={<PrivateRoute><AddJournal /></PrivateRoute>}></Route>
      <Route path="/EditJournal/:id" element={<PrivateRoute><EditJournal /></PrivateRoute>}></Route>
      <Route path="/AddElement/:type" element={<PrivateRoute><AddElement /></PrivateRoute>}></Route>
      <Route path="/EditElement/:type/:id" element={<PrivateRoute><EditElement /></PrivateRoute>}></Route>
      <Route path="/AddFunds/:type" element={<PrivateRoute><AddFunds /></PrivateRoute>}></Route>
      <Route path="/EditFunds/:type/:id" element={<PrivateRoute><EditFunds /></PrivateRoute>}></Route>
      <Route path="/AddEvaluation/:type" element={<PrivateRoute><AddEvaluation /></PrivateRoute>}></Route>
      <Route path="/EditEvaluation/:type/:id" element={<PrivateRoute><EditEvaluation /></PrivateRoute>}></Route>
      <Route path="/EditAccountConfig/:id" element={<PrivateRoute><EditAccountConfig /></PrivateRoute>}></Route>
      <Route path="/File" element={<PrivateRoute><File /></PrivateRoute>}></Route>
      <Route path="/Blotter" element={<PrivateRoute><Blotter /></PrivateRoute>}></Route>
      <Route path="/AddProject" element={<PrivateRoute><AddProject /></PrivateRoute>}></Route>
      <Route path="/EditProject/:id" element={<PrivateRoute><EditProject /></PrivateRoute>}></Route>
      <Route exact path="/" element={<PrivateRoute><Main /></PrivateRoute>}></Route>
      
    </Routes>
  );
}

export default Router;
