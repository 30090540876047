import React from 'react';
import {Box, Form, FormField, TextInput,DateInput, Button,Card,CardHeader, CardBody, Layer, Text, Anchor, Table, TableBody, TableRow, TableCell, TableHeader} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../AxiosInstance';
import { TYPE_STOCK, TYPE_DEBT, TYPE_CLAIM, TYPE_AMORTIZATION } from '../../Params';
import Breadcrumb from '../Custom/Breadcrumb';


const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}  



const EditEvaluation = () => {
    const [label, setLabel] = useState("");
    const [update, setUpdate] = useState(true)
    const [title, setTitle] = useState("");
    
    let {type, id} = useParams();
    
    const getLabel = (type, id) => {
        let label_name = ""
        let value = [];
        switch(parseInt(type)){
            case TYPE_STOCK:
                label_name = "Edition d'un stock";
                value = [{'label': 'Stock', 'link': '/Stock'},{'label': "Edition d'un stock", 'link': '/EditEvaluation/'+type+"/"+id}]
                break;
            case TYPE_DEBT:
                label_name ="Edition d'une dette";
                value = [{'label': 'Dettes', 'link': '/Dettes'},{'label': "Edition d'une dette", 'link': '/EditEvaluation/'+type+"/"+id}]
                break;
            case TYPE_CLAIM:
                label_name = "Edition d'une créance";
                value = [{'label': 'Créances', 'link': '/Creances'},{'label': "Edition d'une créance", 'link': '/EditEvaluation/'+type+"/"+id}]
                break;
            case TYPE_AMORTIZATION:
                label_name = "Edition d'un amortissement";
                value = [{'label': 'Amortissement', 'link': '/Amortization'},{'label': "Edition d'un amortissement", 'link': '/EditEvaluation/'+type+"/"+id}]
                break;
            default:
                label_name ="Undefined";
        }
        setTitle(<Breadcrumb elements={value}/>)
        return {label_name}
    }

    useEffect(() => {
       let {label_name, breadcrumb_value} = getLabel(type, id);
       setLabel(label_name);
       setUpdate(false)
       
    }, []); 
   

    return(
        <Box direction="column" fill>
            {title}
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large" >
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>{label}</CardHeader>
                    <CardBody pad="small" height="medium">
                        <Box pad="medium" direction='row'>
                            <EvaluationForm />
                        </Box>  
                    </CardBody>
                </Card>
            </Box> 
        </Box>
    );
}

const EvaluationForm = () => {

    const [date, setDate] = useState(""); 
    const [amount, setAmount] = useState(0);
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const [update, setUpdate] = useState(true)
    const [timestamp, setTimestamp] = useState(0);

    let {type, id} = useParams();

    
    const getData = () => {
        axiosInstance.get("/cash/evaluation/?id="+id+"&type="+type).then(result => {
            if (result.data.data){
                let data = result.data.data[0];
                setName(data.name);
                setAmount(data.amount);
                setTimestamp(data.date);
                setDate(new Date((data.date * 1000)+ 1000))    
                console.log(date, data.date,data.readable_date);  
            }
        });
        
    }

    useEffect(() => {
        getData(); 
    }, []);

    const handleSubmit = () => {
        let data = {type:type, name:name, amount:amount, date: timestamp}
        axiosInstance.put(`/cash/evaluation/${id}/`, data).then((response) => {
            let result = response.data
            setMessage(result.message);
            setVisible(true);
        })
    }

    const handleDate = (value) => {
        let new_ts = parseInt((new Date(value.split("T")[0]).getTime() / 1000).toFixed(0) - 1)
        setDate(value);
        setTimestamp(new_ts);
    }

    return (
        <Form onSubmit={(value) => handleSubmit()} > 
            {visible && (<Layer
                onEsc={() => setVisible(false)}
                onClickOutside={() => setVisible(false)}
                margin="medium"
                style={{padding: "2%"}}
              >
              <Text>{message}</Text>
                <Box direction="row"  pad="xsmall" alignSelf="end" >
                  <Anchor label="Fermer" color="primary" align='start' onClick={() => setVisible(false)} />
                </Box>
              </Layer>)}
            <FormField name="date" htmlFor="textinputDate" label="Date" margin="small" >
                <DateInput id="textinputAmount" value={date} required name="date" onChange={(event) => handleDate(event.value)} format="dd/mm/yyyy"/>
            </FormField>
           <FormField name="name" htmlFor="textinputName" label="Objet" margin="small">
                <TextInput id="textinputName" value={name} required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <FormField name="amount" htmlFor="textinputName" label="Montant" margin="small" >
                <TextInput id="textinputAmount" value={amount}  required name="amount" onChange={(event) => setAmount(event.target.value)} type="number"/>
            </FormField>
            
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Modifier" color="primary" style={{width: "50%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

const FileEvaluation = () => {
    const [files, setFiles] = useState([]);
    let {type, id} = useParams();

    useEffect(() => {
        axiosInstance.get("/edm/cash_document/?eval_id="+id).then(response =>{
            setFiles(response.data); 
            console.log(files);
        });
    },[]);

    return(
        <Table>
            <TableHeader>
                <TableRow>
                    <TableCell>Fichier</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {files.map(file => {
                    <TableRow key={file}>
                        <TableCell>{file.file}</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    ); 
}

export default EditEvaluation; 