import React from 'react';
import { useState, useEffect } from "react"
import axiosInstance from "../../AxiosInstance";
import { Box, DataTable, Text, Tabs, Tab, DateInput, FormField, Form  } from "grommet";
import { CustomCard } from "../Custom/CustomCard";
import Breadcrumb from "../Custom/Breadcrumb";
import { BLOTTER_TYPE_BANQUE, BLOTTER_TYPE_CASH } from "../../Params";
import { DownloadButton } from "../Custom/CustomButton";


const Blotter = () => {
    const title_bank = "Brouillard de banque";
    const title_cash = "Brouillard de caisse";
    const elements = [{'label': "Brouillard", 'link': '/CashBlotter'}]
    return (
        <Box  direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Tabs style={{color: "primary"}} pad="small" >
                <Tab title={title_cash} >
                    <CustomCard title={title_cash} 
                        content={<BlotterContent type={BLOTTER_TYPE_CASH}/>}
                    />    
                </Tab>
                <Tab title={title_bank}>
                    <CustomCard title={title_bank} 
                        content={<BlotterContent type={BLOTTER_TYPE_BANQUE}/>}
                    />
                </Tab>
            </Tabs>
        </Box>)
    }

const BlotterContent = (props) => {
    const today = new Date();

    const tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1);
    const blotter_type = props.type;
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(today.toISOString());
    const [endDate, setEndDate] = useState(tomorrow.toISOString());

    let columns = [
        {
          property: 'name',
          header: <Text>Objet</Text>,
          sortable : true,
          search: true,
        },
        {
            property: 'amount_in',
            header: <Text>Entrée</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'amount_out',
            header: <Text>Sortie</Text>,
            sortable : true,
            search: true, 
        },
       
        {
            property: 'balance',
            header: <Text>Solde</Text>,
            sortable : true,
            search: true, 
        },

        {
            property: 'fund_date',
            header: <Text>Date</Text>,
            sortable : true,
            search: true, 
        },
        
    ]

    const convert_date_to_ts = (date) => {
        date = date.split('T')[0];
        return parseInt((new Date(date).getTime() / 1000).toFixed(0));
    }

    const getData = () => {
        const start_date =  convert_date_to_ts(startDate) - 3600;
        const end_date = convert_date_to_ts(endDate) + 82799;
        axiosInstance.get("/cash/cashblotter/?blotter_type="+blotter_type+"&start_date="+start_date+"&end_date="+end_date).then(result => {
            setData(result.data);
        });
    }

    const handleStartDate = (value) => {
        setStartDate(value)
        getData();
    }

    const handleEndtDate = (value) => {
        setEndDate(value);
        getData();
    }


    useEffect(() => {
        getData();
    },[blotter_type, startDate, endDate])

    return (

        

        <Box>
            <Form onSubmit={({ value }) => {}}>
                
                <Box direction="row"  pad="xsmall"  justify="between" width="xlarge">
                    <FormField name="start_date" htmlFor="start_date" label="Début">
                        <DateInput value={startDate} id="textinputAmount" required name="start_date" onChange={(event) => handleStartDate(event.value)} format="dd/mm/yyyy"/>
                    </FormField>

                    <FormField name="end_date" htmlFor="end_date" label="Fin">
                        <DateInput value={endDate} id="textinputAmount" required name="end_date" onChange={(event) => handleEndtDate(event.value)} format="dd/mm/yyyy"/>
                    </FormField>

                    <Box direction="row" pad="small" justify="between">
                        <Box direction="column" pad="xsmall" >
                            <DownloadButton url="/cash/cashblotter/" label="Telecharger pdf" blotter_type={blotter_type} format="pdf" start_date={convert_date_to_ts(startDate)} end_date={convert_date_to_ts(endDate)}/>
                        </Box>
                        <Box direction="column" pad="xsmall" >
                            <DownloadButton url="/cash/cashblotter/" label="Telecharger excel" blotter_type={blotter_type} format="excel" start_date={convert_date_to_ts(startDate)} end_date={convert_date_to_ts(endDate)}/>
                        </Box>
                    </Box>
                </Box>
           
            </Form>
       
            <Box >
                <DataTable 
                    columns={columns} 
                    data={data} 
                    paginate={true}
                    sortable = {true}
                    step={100}
                    margin="xxsmall"
                    background={{header:"bluetable", body:["white", "bluetable"]}}
                    pad="small"
                />
            </Box>
        </Box>
    )

}

export default Blotter