import React from 'react';
import { Form, Box, CheckBox, Text, Tip, DataTable } from "grommet"
import { Edit } from "grommet-icons";
import { useState, useEffect } from "react";
import axiosInstance from "../../AxiosInstance";
import { useNavigate } from "react-router-dom";

const EditButton = (props) => {
    const navigate = useNavigate();
    let id = props.id
    return (<Tip content="Editer"><Edit color="primary" onClick={() =>navigate("/EditAccountConfig/"+id)}/></Tip> )
}


const General = () => {
    const [checked, setChecked] = useState(false); 
    const [data, setData] = useState([]);

    const getData = () => {
        axiosInstance.get("/cash/evaluation_config/").then(result => {
            setData(result.data);
        }).catch(err => { console.log(err)});;
    }

    const getConfig = () => {
        axiosInstance.get("/cash/cashconfig/").then(result => {
            setChecked(result.data.automatic_validation);
        });
    }

    const changeConfig = (value) => {
        setChecked(value);
        axiosInstance.post("/cash/cashconfig/",{"value":value}).then(result => {
            setChecked(result.data.automatic_validation);
        });
    }

    useEffect(() => {
        getData();
    },[])

    useEffect( () => {
        getConfig();
    }, [])

    let columns = [
        {
          property: 'name',
          header: <Text>Nom</Text>,
          primary: true,
          sortable : true,
          
        },
        {
            property: 'debit_account_name',
            header: <Text>Compte Débit</Text>,
            primary: false,
            sortable : true,
            
        },
        {
            property: 'credit_account_name',
            header: <Text>Compte Crédit</Text>,
            primary: false,
            sortable : true,
            
        },
        {
            property: 'journal_name',
            header: <Text>Journal</Text>,
            primary: false,
            sortable : true,
            
        },
        {
            property: 'action',
            header: <Text>Actions</Text>,
            
            render : datum => (
                <Box direction="row" justify="between"  pad="medium">
                    <EditButton id={datum.id}/>  
                </Box>    
             ) , 
        },
    ]

    return (
        <Form onSubmit={({ value }) => {}}>
            <Box direction="row"  pad="xsmall" width="xlarge" justify="between"> 
            <CheckBox
                checked={checked}
                label="Valider les opérations automatiquement"
                onChange={(event) => changeConfig(event.target.checked)}
                style = {{borderColor:"primary"}}
            />
            </Box>
            <Box  direction="column" width="xlarge" margin={{top: "3%"}}>
            <DataTable 
                columns={columns} 
                data={data} 
                paginate={true}
                sortable = {true}
                step={5}
                margin="xxsmall"
                background={{header:"bluetable", body:["white", "bluetable"]}}
                pad="small"
            />
            </Box>
        </Form>
    )

}

export default General;