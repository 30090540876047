import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Select, Notification} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../AxiosInstance';
import Breadcrumb from '../../Custom/Breadcrumb';
import { TYPE_ELEMENT, TYPE_MEANS_OF_PAYMENT } from '../../../Params';



const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const EditElement = () => {
    let {type, id} = useParams();
    const [title, setTitle] = useState("");

    useEffect(() => {
        let value = []
        switch(parseInt(type)){
            case TYPE_ELEMENT:
                value = [{'label': 'Paramètres', 'link': '/Settings'},{'label': "Edition d'un élément", 'link': '/EditElement/'+type+'/'+id}]
                break;
            case TYPE_MEANS_OF_PAYMENT:
                value = [{'label': 'Paramètres', 'link': '/Settings'},{'label': "Edition d'un moyen de paiement", 'link': '/EditElement/'+type+'/'+id}]
                break;
            default:
                value = "je suis perdu"
                break;
        }
        setTitle(<Breadcrumb elements={value}/>)
    }, [])

    return (
        <Box direction="column" fill>
            {title}
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Edition d'un élément</CardHeader>
                    <CardBody pad="small" height="medium" flex><ElementForm /></CardBody>
                </Card>
            </Box>  
        </Box>       
    );
}

const ElementForm = () => {
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [histAccounts, sethistAccounts] = useState([]);
    const [account, setAccount] = useState("");
    const navigate = useNavigate();

    let {type, id} = useParams()

    
    const getAccounts = () => {
        axiosInstance.get("/api/account/").then((response) => {
            setAccounts(response.data.sort());
            sethistAccounts(response.data.sort());
            axiosInstance.get("/cash/elements/?id="+id+"&type="+type).then(result => {
                if (result.data){
                    let data = result.data[0];
                    setName(data.name);
                    response.data.map(res => {
                        if(res.id === data.account){
                            setAccount(res);
                        }
                    })
                    
                }
            });
        });
       
    }

   
    const handleDebitSearch = (e) => {
        if(e){
            let search = e.toLowerCase();
            let result = accounts.filter(element => {
                 
                if (element.code.toLowerCase().includes(search) || element.nom.toLowerCase().includes(search)){
                    return element;
                }
            });
            setAccounts(result);
        }
        else{
            setAccounts(histAccounts)
        }
    }

    useEffect(() => {
        getAccounts();
    }, [])

    const handleSubmit = () => {
        axiosInstance.put(`/cash/elements/${id}/`, {"name": name, 'account': account.id, 'type':type}).then((response) => {
            let result = response.data
            if(result.code == 200){
                navigate("/Settings")
            }
            else{
                setMessage(result.message);
                setVisible(true);
            }
        })
    }

    const getAccountName = (ac) => {
        return `${ac.code}-${ac.nom}`;
    }

    return (
        <Form onSubmit={(value) => handleSubmit()} > 
            {visible && (<Notification
                title="Erreur"
                message={message}
                onClose={() => {setVisible(false)}}
                status = "warning"
                style={{backgroundColor: "neutral-4", "color":"black"}}
            />)}
            


            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required value={name} name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <FormField name="account" htmlFor="account" label="Compte" width="50%" margin="small">
                <Select 
                    options={accounts} 
                    value={account} 
                    onChange={({option}) => setAccount(option) } 
                    labelKey={(opt) => getAccountName(opt)} 
                    onSearch = {(e) => {handleDebitSearch(e)}}
                    required/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Modifier" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default EditElement; 