import React from 'react';
import { PowerShutdown } from 'grommet-icons';
import { Heading, Button, Box } from "grommet";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosInstance from '../AxiosInstance';

const Bar = (props) => (
    <Box
      tag='header'
      direction='row'
        align='center'
        justify='between'
        background='white'
        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        elevation='medium'
        style={{ zIndex: '1' }}
        {...props}
      />
    );

const AppBar = () => {
    const company = localStorage.getItem("company_name");
    const navigate = useNavigate();
    const [infos, setInfos] = useState({}) 

    const handleLogout = () => {
      localStorage.clear();
      navigate("/login"); 
    }

    useEffect(() =>{
      axiosInstance.get("/api/infos/").then(result => {
        console.log(result.data)
        setInfos(result.data);
        
      });
    },[]);

    return (
        <Bar background='white' border={{side:"bottom", color:"neutral-4",}} >
          <Heading level='2' margin='none' color="primary">CAISSE</Heading>
          <Heading level='4' margin='none' color="primary">Bienvenue {company}</Heading>
          <Heading level='4' margin='none' color="primary">Caisse: {infos.cash_amount} </Heading>
          <Heading level='4' margin='none' color="primary">Banque: {infos.bank_amount} </Heading>
          <Heading level='4' margin='none' color="primary">Exercice: {infos.exercise_year}</Heading>
          <Button icon={<PowerShutdown color="primary"/>} onClick={() => {handleLogout()}} />
        </Bar>
        ); 
}

export default AppBar;