import React from 'react';
import { CustomCard } from "../Custom/CustomCard";
import { Box, Button, Text, Tip, DataTable, Notification, Layer } from "grommet";
import { useState, useEffect } from "react";
import { Download } from "grommet-icons";
import axiosInstance from "../../AxiosInstance";
import { Trash } from "grommet-icons";
import Breadcrumb from "../Custom/Breadcrumb";

function File(){
    const elements = [{'label': 'Gestion des documents', 'link': '/File'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box flex align='center' justify='between' pad="medium" direction="column">
                <CustomCard title="Gestion des fichiers" content={<FileList/>} />
            </Box>
        </Box>
    );
}

const DownloadButton = (props) => {
    let id = props.id

    const handleDownload = () => {
        axiosInstance.post("/edm/cash_document/", {"id": id}).then((result) => {
            if (result.data.code === 200){
               console.log(result.data.path);
               const link = document.createElement('a');
               link.href = result.data.path;
               link.setAttribute(
                 'download',
                 `${result.data.filename}`,
               );
               document.body.appendChild(link);
               link.click();
               link.parentNode.removeChild(link);
            }
        })
    }
    
    return (<Tip content="Editer"><Download color="primary" onClick={() =>handleDownload()}/></Tip> )
}


export const FileList = () => {

    const [data, setData] = useState([]);
    const [select, setSelect] = useState([]);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(""); 
    const [apiMessage, setApiMessage] = useState("");
    const [showError, setShowError] = useState(false); 
    

    const getData = () => {
        axiosInstance.get("/edm/cash_document/").then(result => {
            setData(result.data);
        });
    }

    

    useEffect(() => {
        getData();
    },[])

    const confirmDelete = () => {
        setMessage("Voulez-vous vraiment supprimer ces éléments ?")
        setVisible(true);
    }

    const handleDelete = () => {
        let api_msg = []
        if (select.length !== 0) {
            
            for (let i=0; i< select.length; i++){
                axiosInstance.delete(`/edm/cash_document/${select[i]}/`).then((response) => {
                    console.log(response.data.message)
                });
                api_msg = "Les fichiers ont bien été supprimés"
            }
           
        }
        else{
            api_msg = "Aucune donnée sélectionnée";
        }
        setApiMessage(api_msg); 
        setVisible(false);
        setShowError(true); 
        getData();
    }
    
    let columns = [
        {
            property: 'id',
            header: <Text>ID</Text>,
            primary: true,
           
        },
        {
          property: 'readable_date',
          header: <Text>Date Ajout</Text>,
          sortable : true,
          search: true,
        },
        {
            property: 'file_name',
            header: <Text>Fichier</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
            property: 'source',
            header: <Text>Source</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
            property: 'action',
            header: <Text>Actions</Text>,
            
            render : datum => (
                <Box direction="row" justify="between"  pad="medium">
                    <DownloadButton id={datum.id}/>  
                </Box>    
             ) , 
        },
    ]


    return (<Box pad="xsmall" direction="column" >
    <Box direction="column" pad="xsmall" justify="between">
    {showError && (
    <Notification
        title="Notification"
        message={apiMessage}
        status="warning"
        onClose={() => {setShowError(false)}}
    />)}
    <Box direction="row">
        <Box direction="column">
            <Button type="button"  label="Supprimer" color="primary" icon={<Trash />} margin="xsmall"
                onClick={() => confirmDelete()} />
        </Box>
    </Box>
    <Box  direction="column" width="xlarge" margin={{top: "2%"}} fill>
        <DataTable 
            columns={columns} 
            data={data} 
            paginate={true}
            sortable = {true}
            step={5}
            margin="xxsmall"
            background={{header:"bluetable", body:["white", "bluetable"]}}
            select={select}
            onSelect = {setSelect}
            pad="small"
        />
    </Box>
    {visible && (
        <Layer
            onEsc={() => setVisible(false)}
            onClickOutside={() => setVisible(false)}
            margin="medium"
            style={{padding: "2%"}}
        >
            <Text>{message}</Text>
            <Box direction="row"  fill justify="between" pad="small">
                <Button label="Fermer" color="light-1" onClick={() => setVisible(false)} style={{width: "40%"}} />
                <Button label="Accepter" color="primary" onClick={() => handleDelete()} style={{width: "40%"}} />
            </Box>
        </Layer>
    )}
    </Box>
</Box>)
}


export default File;