export  const theme = {
  global: {
      colors : {
          primary: "#64405C",
          white: "#fff",
          bluetable: "rgba(92,184,250,0.1)",
          border: "primary",
      },
      login : {
          brand: '#228BE6',
      },
      font: {
          family: 'Roboto',
          size: '18px',
          height: '20px',
      },
      focus:{
          border :{
              color: "neutral-4",
          }
      },
  },
  button: {
      border: {
          radius: "3px",
      },
    
      
  },
  tab:{  
    hover : {
        color: "primary",
    }, 
    border:{
        color:"primary"
    },
    active :{
        color: "primary"
    },
    color: "light-6",
    
    },
  
  checkBox:{
      color: "primary",
     
  },
  notification: {
      critical: {
          color : "neutral-4"
      }
    },
    layer:{
        border:{
            radius : "3px"
        },
        padding:"5%"
    }
};
  