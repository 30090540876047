import React from 'react';
import { Box, Button, Text, DataTable,Notification,Layer } from "grommet";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../AxiosInstance";
import { Add } from "grommet-icons";
import { EditButton, DeleteButton, ValidateButton, UploadButton } from "../Custom/CustomButton";



export const EvaluationContent = (props) => {

    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [select, setSelect] = useState(0);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(""); 
    const [apiMessage, setApiMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    let type= props.type

    const handleEdit = (id) => {
        navigate("/EditEvaluation/"+type+"/"+id);
    }
    

    const getData = () => {
        axiosInstance.get("/cash/evaluation/?type="+type).then(result => {
            setData(result.data.data);
        });
    }

    useEffect(() => {
        getData();
    },[])

    const confirmDelete = (id) => {
        setSelect(id);
        setMessage("Voulez-vous vraiment supprimer cette ligne ?")
        setVisible(true);
    }

    const handleDelete = () => {
        axiosInstance.delete(`/cash/evaluation/${select}/`).then((response) => {
            setApiMessage(response.data.message)
        });
        setVisible(false);
        setShowError(true); 
        getData();
    }
    
    let columns = [
        {
            property: 'readable_date',
            header: <Text>Date</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
          property: 'name',
          header: <Text>Objet</Text>,
          primary: true,
          sortable : true,
          search: true,
        },
        {
            property: 'operation_status',
            header: <Text>Statut</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
            property: 'amount',
            header: <Text>Montant</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
            property: 'action',
            header: <Text>Actions</Text>,
            
            render : datum => (
                <Box direction="row" justify="between"  pad="xsmall">
                    {datum.can_edit ? <EditButton id={datum.id} handleClick={handleEdit}/> : <></> }
                    {datum.operation_status === 'Brouillon' ? <><DeleteButton id={datum.id} confirm={confirmDelete} /> <ValidateButton id={datum.id} handleClick={handleValidate} /> </> : <></>}
                    {datum.can_edit ? <UploadButton id={datum.id} type={datum.type} source="eval"/> : <></> }
                </Box>    
             ) , 
        },
    ]

    const handleValidate = (id) => {
        axiosInstance.post('/cash/validate_evaluation/', {"eval_id": id}).then((response) => {
            setApiMessage(response.data.message);
            setShowError(true);
            getData();
        });
    }


    return (<Box align='center' pad="xsmall" direction="column" >
    <Box direction="column" pad="xsmall" justify="between">
    {showError && (
    <Notification
        title="Notification"
        message={apiMessage}
        status="warning"
        onClose={() => {setShowError(false)}}
    />)}
    <Box direction="row">
        <Box direction="column">
            <Button type="button" label="Ajouter" color="primary" icon={<Add />} margin="xsmall" 
                onClick={() => navigate(`/AddEvaluation/${type}`)} />
        </Box>
    </Box>
    <Box  direction="column" width="xlarge" margin={{top: "2%"}}>
        <DataTable 
            columns={columns} 
            data={data} 
            paginate={true}
            sortable = {true}
            step={5}
            margin="xxsmall"
            background={{header:"bluetable", body:["white", "bluetable"]}}
            pad="small"
        />
    </Box>
    {visible && (
        <Layer
            onEsc={() => setVisible(false)}
            onClickOutside={() => setVisible(false)}
            margin="medium"
            style={{padding: "2%"}}
        >
            <Text>{message}</Text>
            <Box direction="row"  fill justify="between" pad="small">
                <Button label="Fermer" color="light-1" onClick={() => setVisible(false)} style={{width: "40%"}} />
                <Button label="Accepter" color="primary" onClick={() => handleDelete()} style={{width: "40%"}} />
            </Box>
        </Layer>
    )}
    </Box>
</Box>)
}