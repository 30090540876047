import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Select, Notification} from 'grommet';
import { useState, useEffect } from 'react';
import axiosInstance from '../../../AxiosInstance';
import Breadcrumb from '../../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const AddJournal = () => {
    const elements = [{'label': 'Paramètres', 'link': '/Settings'}, {'label': 'Ajout d\'un journal', 'link': '/AddJournal'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Ajout d'un journal</CardHeader>
                    <CardBody pad="small" height="medium" flex><JournalForm /></CardBody>
                </Card>    
            </Box> 
        </Box>       
    );
}

const JournalForm = () => {
    const [code, setCode]  = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [histAccounts, sethistAccounts] = useState([]);
    const [account, setAccount] = useState(0);

    const getAccounts = () => {
        axiosInstance.get("/api/account/").then((response) => {
            setAccounts(response.data);
            sethistAccounts(response.data);
            
        })
    }

    useEffect(() => {
        getAccounts();
    }, [])

    const handleSubmit = () => {
        axiosInstance.post("/api/journal/", {"nom": name, "code": code, 'compte': account.id}).then((response) => {
            setMessage(response.data.message);
            setVisible(true);
        })
    }

    const handleAccountSearch = (e) => {
        if(e){
            let search = e.toLowerCase();
            let result = accounts.filter(element => {
                 
                if (element.code.toLowerCase().includes(search) || element.nom.toLowerCase().includes(search)){
                    return element;
                }
            });
            setAccounts(result);
        }
        else{
            setAccounts(histAccounts)
        }
    }

    const getAccountName = (ac) => {
        return `${ac.code}-${ac.nom}`;
    }


    return (
        <Form onSubmit={(value) => handleSubmit()}> 
            {visible && (<Notification
                title="Infos"
                message={message}
                onClose={() => {setVisible(false)}}
                status = "warning"
            />)}

            <FormField name="code" htmlFor="textinputCode" label="Code" margin="small" width="50%">
                <TextInput id="textinputCode" name="code"  required onChange={(event) => setCode(event.target.value)}/>
            </FormField>
            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <FormField name="account" htmlFor="account" label="Compte" width="50%" margin="small">
                <Select 
                    options={accounts} 
                    onChange={({option}) => setAccount(option) } 
                    labelKey={(opt) => getAccountName(opt)}
                    onSearch = {(e) => {handleAccountSearch(e)}}
                    required/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Ajouter" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default AddJournal; 