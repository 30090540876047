import React from 'react';
import { Box, Form, Button, FormField, TextInput, Grommet, Image, Heading, Footer, Text, Anchor } from 'grommet';
import login from '../images/login.jpg';
import { useState } from 'react';
import {siteURL} from "../AxiosInstance";
import { useNavigate } from "react-router-dom";
import { theme } from './theme';
import axios from 'axios';


//axios.defaults.xsrfHeaderName = "X-CSRFToken";
//axios.defaults.xsrfCookieName = 'csrftoken';
//axios.defaults.withCredentials = true;


function Login(){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        if(username && password){
            axios.post(siteURL()+"/auth/get_token_auth/", {username: username, password:password},{ headers: {"Content-Type": "application/json" } }
           ).then(result => {
                result = result.data;
                if(result.code === 200){
                    localStorage.setItem("token", result.token);
                    localStorage.setItem("country_name", result.country_name)
                    localStorage.setItem("company_id", result.company_id);
                    localStorage.setItem("company_name", result.company_name);
                    localStorage.setItem("username", result.username);
                    localStorage.setItem("permsissions", JSON.stringify(result.permissions));
                    navigate("/")
                }
                else{
                    setMessage(result.msg);
                } 
            }).catch( error =>{
                setMessage("Un problème est survenu. Merci de réessayer")
            })
        }
    }

    return (
        <Grommet background="light-2" theme={theme} fill>
            <Box direction='row-responsive' pad="small" height="large">
            <Box direction="column" border={{ color: 'light-6', size: 'small' }}  fill>
                <Image fit="cover" src={login}/>
            </Box>
            <Box direction="column"  pad="xlarge" width="large">
                <Heading textAlign='center' color={"neutral-4"}>Caisse</Heading>
                <Text textAlign='center' background="light-2" color="neutral-4">{message}</Text>
                <Form onSubmit={(value) => handleSubmit()}>
                    <FormField name="name" htmlFor="textinputId" label="Identifiants">
                        <TextInput id="textinputId" name="name" required onChange={(event) => setUsername(event.target.value)}/>
                    </FormField>
                    <FormField name="password" htmlFor="textinput-pwd" label="Mot de passe">
                        <TextInput id="textinput-pwd" required name="password" type="password" onChange={(event) => setPassword(event.target.value)}/>
                    </FormField>
                    <Box direction="column" gap="medium">
                        <Box direction="row" gap="xlarge" justify='between'>
                            <Button type="submit" primary label="Connexion" color="neutral-4" style={{width: "50%"}}/>
                            {/*<Button type="button" primary label="Inscription" color="neutral-1" style={{width: "50%"}} onClick={(event) => navigate("/Register")}/> */}
                        </Box>
                        {/*<Button type="reset" primary label="Mot de passe oublié" color="light-5"/>*/}
                    </Box>
                </Form>

            </Box>
            
            </Box>
            <Footer textAlign="center"   pad="medium"> 
                
                <Anchor label="" />
                <Anchor label="" />
                <Text color="neutral-4" weight="bold" >Caisse By IWO@2022</Text>
                <Anchor label="" />
                <Anchor label="" />
            </Footer>
        </Grommet>
         
    )
}

export default Login;