import React from 'react';
import { useState, useEffect } from "react";
import { Text, Box, Button, DataTable, Layer, Notification } from "grommet";
import { Add } from "grommet-icons";
import axiosInstance from "../../../AxiosInstance";
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton } from "../../Custom/CustomButton";

const Journal = () => {
    const [data, setData] = useState([]); 
    const navigate = useNavigate();
    const [select, setSelect] = useState(0);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(""); 
    const [apiMessage, setApiMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    let columns = [
        {
            property: 'code',
            header: <Text>Code</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'nom',
            header: <Text>Nom</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'account_name',
            header: <Text>Compte</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'id',
            header: <Text>Actions</Text>,
            
            render : datum => (
                <Box direction="row" justify="between"  pad="xsmall">
                    <EditButton id={datum.id} handleClick={handleEdit}/>  
                    <DeleteButton id={datum.id} confirm={confirmDelete} />
                </Box>    
             ) , 
        },
        
    ]

    const getAccounts = () => {
        axiosInstance.get("/api/journal/", data).then(result => {
            setData(result.data);
        });
    }

    const confirmDelete = (id) => {
        setMessage("Voulez-vous vraiment supprimer ce journal ?")
        setVisible(true);
        setSelect(id)
    }

    const handleDelete = () => {
        axiosInstance.delete(`/api/journal/${select}/`).then((response) => {
            setApiMessage(response.data.message);
        });
        
        getAccounts();
        setVisible(false);
        setShowError(true); 
    }

    const handleEdit = (id) => {
        navigate("/EditJournal/"+id)
    }

    useEffect(() =>{
        getAccounts();
    }, [])
    

    return (
        
        <Box direction="column" pad="small" justify="between">
            {showError && (
            <Notification
                title="Notification"
                message={apiMessage}
                status="warning"
                onClose={() => {setShowError(false)}}
            />)}
            <Box direction="row"> 
                <Box direction="column"> 
                    <Button type="button" label="Ajouter" color="primary" icon={<Add />}  
                    onClick={() => navigate('/AddJournal')} margin="xsmall"  />
                </Box>
            </Box>
            <Box  direction="column" width="xlarge"  margin={{top: "2%"}}>
             <DataTable 
                columns={columns} 
                data={data} 
                paginate={true}
                sortable = {true}
                step={5}
                margin="xsmall"
                background={{header:"bluetable", body:["white", "bluetable"]}}
                
                />
            </Box>
            {visible && (
                <Layer
                    onEsc={() => setVisible(false)}
                    onClickOutside={() => setVisible(false)}
                    margin="medium"
                    style={{padding: "2%"}}
                >
                    <Text>{message}</Text>
                    <Box direction="row"  fill justify="between" pad="small">
                        <Button label="Fermer" color="light-1" onClick={() => setVisible(false)} style={{width: "40%"}} />
                        <Button label="Accepter" color="primary" onClick={() => handleDelete()} style={{width: "40%"}} />
                    </Box>
                </Layer>
      )}
        </Box>
        
    )
}

export default Journal;