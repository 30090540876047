import React from 'react';
import { useState, useEffect } from "react";
import { Text, Box, Button, DataTable, Layer, Anchor } from "grommet";
import { Add } from "grommet-icons";
import axiosInstance from "../../../AxiosInstance";
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton } from "../../Custom/CustomButton";

const ListProject = () => {
    const [data, setData] = useState([]); 
    const navigate = useNavigate();
    const [select, setSelect] = useState(0);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(""); 
    const [apiMessage, setApiMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    let columns = [
        {
            property: 'created_date',
            header: <Text>Date Ajout</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'name',
            header: <Text>Libellé</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'id',
            header: <Text>Actions</Text>,
            
            render : datum => (
                <Box direction="row" justify="between"  pad="xsmall">
                    <EditButton id={datum.id} handleClick={handleEdit}/>  
                    <DeleteButton id={datum.id} confirm={confirmDelete} />
                </Box>    
             ) , 
        },
        
    ]

    const getProjects = () => {
        axiosInstance.get("/project/projects/", data).then(result => {
            setData(result.data);
        });
    }

    const confirmDelete = (id) => {
        setMessage("Voulez-vous vraiment supprimer ce projet ?")
        setVisible(true);
        setSelect(id)
    }

    const handleDelete = () => {
        axiosInstance.delete(`/project/projects/${select}/`).then((response) => {
            setApiMessage(response.data.message);
        });
        
        getProjects();
        setVisible(false);
        setShowError(true); 
    }

    const handleEdit = (id) => {
        navigate("/EditProject/"+id)
    }

    useEffect(() =>{
        getProjects();
    }, [])
    

    return (
        
        <Box direction="column" pad="small" justify="between">
            {showError && (
                <Layer
                    onEsc={() => setShowError(false)}
                    onClickOutside={() => setShowError(false)}
                    margin="medium"
                    style={{padding: "2%"}}
                >
                <Text>{apiMessage}</Text>
                <Box direction="row"  pad="xsmall" alignSelf="end" >
                    <Button label="Fermer" color="primary" align='start' onClick={() => setShowError(false)} />
                </Box>
            </Layer>)}
            <Box direction="row"> 
                <Box direction="column"> 
                    <Button type="button" label="Ajouter" color="primary" icon={<Add />}  
                    onClick={() => navigate('/AddProject')} margin="xsmall"  />
                </Box>
            </Box>
            <Box  direction="column" width="xlarge"  margin={{top: "2%"}}>
             <DataTable 
                columns={columns} 
                data={data} 
                paginate={true}
                sortable = {true}
                step={5}
                margin="xsmall"
                background={{header:"bluetable", body:["white", "bluetable"]}}
                />
            </Box>
            {visible && (
                <Layer
                    onEsc={() => setVisible(false)}
                    onClickOutside={() => setVisible(false)}
                    margin="medium"
                    style={{padding: "2%"}}
                >
                    <Text>{message}</Text>
                    <Box direction="row"  fill justify="between" pad="small">
                        <Button label="Fermer" color="light-1" onClick={() => setVisible(false)} style={{width: "40%"}} />
                        <Button label="Accepter" color="primary" onClick={() => handleDelete()} style={{width: "40%"}} />
                    </Box>
                </Layer>
      )}
        </Box>
        
    )
}

export default ListProject;