import React from 'react';
import { CustomCard } from "../Custom/CustomCard";
import { Box } from "grommet";
import { TYPE_STOCK, TYPE_DEBT, TYPE_CLAIM, TYPE_AMORTIZATION } from "../../Params";
import { EvaluationContent } from "./EvaluationContent";
import Breadcrumb from "../Custom/Breadcrumb";

export function Stock() {
    const elements = [{'label': 'Stock', 'link': '/Stock'}]
    return (
        <Box direction="column" fill>
        <Breadcrumb elements={elements}/> 
            <Box flex align='center' justify='between' pad="medium" direction="column">
                <CustomCard title="Stock" content={<EvaluationContent type={TYPE_STOCK}/>} />
            </Box>
        </Box>        
    );
}

export function Amortization() {
    const elements = [{'label': 'Amortissement', 'link': '/Amortization'}]
    return (
        <Box direction="column" fill>
        <Breadcrumb elements={elements}/> 
            <Box flex align='center' justify='between' pad="medium" direction="column">
                <CustomCard title="Amortissement" content={<EvaluationContent type={TYPE_AMORTIZATION}/>} />
            </Box>
        </Box>        
    );
}

export function Dettes() {
    const elements = [{'label': 'Dettes', 'link': '/Dettes'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box flex align='center' justify='between' pad="medium" direction="column">
                <CustomCard title="Dettes" content={<EvaluationContent type={TYPE_DEBT}/>} />
            </Box>
        </Box>        
    );
}

export function Creances() {
    const elements = [{'label': 'Créances', 'link': '/Creances'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box flex align='center' justify='between' pad="medium" direction="column">
                <CustomCard title="Créances" content={<EvaluationContent type={TYPE_CLAIM}/>} />
            </Box>
        </Box>        
    );
}

