import React from 'react';
import { Box, Form, Button, FormField, TextInput, Grommet, Image, Heading, Footer, Text, Anchor, Select, grommet } from 'grommet';
import register from '../images/register.jpg';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { deepMerge } from "grommet/utils";
import axiosInstance from "../AxiosInstance";

const colors = {
    selected: "neutral-4"
};

const theme = deepMerge(grommet, {
    global: {
        colors, 
        login : {
            brand: '#228BE6',
        },
        font: {
            family: 'Roboto',
            size: '18px',
            height: '20px',
        },
        focus:{
            border :{
                color: "neutral-4",
            }
        },
    },
    button: {
        border: {
            radius: "3px",
        },
    },

    selected:{
            background:"neutral-4",
    }
    
});

const Register = () => {

    const [password, setPassword] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [username, setUsername] = useState("");
    const navigate = useNavigate();

    

    


    useEffect(() =>{
        axiosInstance.get("/api/country/").then((result) => {
            setCountries(result.data);
        }); 
    }, []);

    const handleSubmit = () => {
        let data = {username: username, password:password, company_name:company, phone_number:phonenumber, country_id:country, app_name:"cash_register" }
        axiosInstance.post("/auth/cash_user/",data,).then(result => {
            console.log(result.data);
            result = result.data;
            if(result.code === 200){
                
            }
            else {
                setMessage(result.message);
            }
        }).catch(error =>{
                setMessage("Login ou mot de passe incorrect")
        });
    }

    return (
        <Grommet background="light-2" theme={theme} full>
            <Box direction='row-responsive' pad="small" height="large">
                <Box direction="column" border={{ color: 'light-6', size: 'small' }}  width="xlarge">
                    <Image fit="cover" src={register}/>
                </Box>
                <Box direction="column"  pad="medium" width="large">
                    <Heading textAlign='center' color={"neutral-4"} level="3">Rejoignez-nous</Heading>
                    <Text textAlign='center' background="light-2" color="neutral-4">{message}</Text>
                    <Form onSubmit={(value) => handleSubmit()}>
                        <FormField name="username" htmlFor="username" label="Identifiant">
                            <TextInput id="username" name="username" required onChange={(event) => setUsername(event.target.value)}/>
                        </FormField>
                        <FormField name="password" htmlFor="password" label="Mot de passe">
                            <TextInput id="password" name="password" required onChange={(event) => setPassword(event.target.value)} type="password"/>
                        </FormField>
                        <FormField name="country" htmlFor="country" label="Pays">
                           <Select options={countries} onChange={({option}) => setCountry(option.id) } labelKey="name" required/>
                        </FormField>
                        <FormField name="phonenumber" htmlFor="phonenumber" label="Téléphone">
                            <TextInput id="phonenumber" name="phonenumber" required onChange={(event) => setPhoneNumber(event.target.value)}/>
                        </FormField>
                        <FormField name="company" htmlFor="company" label="Entreprise">
                            <TextInput id="company" name="company" required onChange={(event) => setCompany(event.target.value)}/>
                        </FormField>
                        <Box direction="column" gap="medium">
                            <Box direction="row" gap="xlarge" justify='between'>
                                <Button type="submit" primary label="S'inscrire" color="neutral-4" style={{width: "50%"}}/>
                                <Button type="button" primary label="Connexion" color="neutral-1" style={{width: "50%"}} onClick={(event) => navigate("/login")}/>
                            </Box>
                        </Box>
                    </Form>
                </Box>
            </Box>
            <Footer textAlign="center"   pad="medium"> 
                <Anchor label="" />
                <Anchor label="" />
                <Text color="neutral-4" weight="bold" >Caisse By IWO@2022</Text>
                <Anchor label="" />
                <Anchor label="" />
            </Footer>
        </Grommet>)
}

export default Register; 