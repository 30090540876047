import React from 'react';
import { Box, Heading } from "grommet";
import { CustomCard } from "../components/Custom/CustomCard";

export const hasAccess = (perms) => {
    const user_perms = JSON.parse(localStorage.getItem('permsissions'));
    return user_perms.some(elem => elem.toLowerCase() === perms); 
}

export const ProtectedRoute = (props)  => {
    return hasAccess(props.perms) ? props.children : <ErrorAccess /> 
}

export const ErrorAccess = () => {
    return (
        <>
            <Box flex align='center' justify='between' pad="medium" direction="column">
                <CustomCard title="Paramètres" content={<Errormessage/>}/>
            </Box>    
        </>     
                
    )}

const Errormessage = () => {return (<Heading level="3">Vous n'êtes pas autorisé à voir cette page</Heading>)}


