import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Select, Layer, Text, Anchor, DateInput} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../AxiosInstance';
import { TYPE_ELEMENT, TYPE_MEANS_OF_PAYMENT, INFLOW, OUTFLOW } from '../../Params';
import Breadcrumb from '../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const EditFunds = () => {
    let {type, id} = useParams();
    const [title, setTitle] = useState("");

    useEffect(() => {
        let value = []
        switch(parseInt(type)){
            case INFLOW:
                value = [{'label': 'Entrée de fond', 'link': '/Cashin'},{'label': "Edition d'une entrée de fonds", 'link': '/EditFunds/'+type+'/'+id}]
                break;
            case OUTFLOW:
                value = [{'label': 'Sortie de fond', 'link': '/Cashout'},{'label': "Edition d'une sortie de fonds", 'link': '/EditFunds/'+type+'/'+id}]
                break;
            default:
                break;
        }
        setTitle(<Breadcrumb elements={value}/>)
    }, [])
    
   

    return(
        <Box direction="column" fill>
            {title}
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Edition d'un élément</CardHeader>
                    <CardBody pad="small" height="medium" flex overflow="auto"><FundsForm /></CardBody>
                </Card>
                
            </Box>
        </Box>      
    );
}

const FundsForm = () => {

    const [date, setDate] = useState(""); 
    const [timestamp, setTimestamp] = useState(0);
    const [elements, setElements] = useState([]);
    const [payments, setPayments] = useState([])
    const [element, setElement] = useState({});
    const [payment, setPayment] = useState({});
    const [amount, setAmount] = useState([])
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState([]);
    const navigate = useNavigate();

    let {type, id} = useParams()

    const getElements = () => {
        axiosInstance.get("/cash/elements/?type="+TYPE_ELEMENT).then(result => {
            setElements(result.data);
        });
    }

    const getPayments = () => {
        axiosInstance.get("/cash/elements/?type="+TYPE_MEANS_OF_PAYMENT).then(result => {
            setPayments(result.data);
        });
    }

    const getElementName = (ac) => {
        return `${ac.name}`;
    }

    const getProjects = () => {
        axiosInstance.get("/project/projects/").then(result => {
            setProjects(result.data);
        });
    }
    
    
    const getFunds = () => {
        axiosInstance.get("/cash/cashfunds/?id="+id+"&type="+type).then(result => {
            if (result.data.data){
                let data = result.data.data[0];
                setName(data.name);
                setAmount(data.amount)
                setTimestamp(data.date);
                setDate(new Date((data.date * 1000) + 1000))
                payments.map((res) => {
                    if(res.id == data.means_of_payment){
                        setPayment(res);
                    }
                });
                elements.map((res) => {
                    if(res.id === data.element){
                        setElement(res);
                    }
                })
                projects.map((res) => {
                    if(res.id === data.project){
                        setProject(res);
                    }
                })
                    
            }
        });
       
    }

    useEffect(() => {
        getElements();
    },[])

    useEffect(() => {
        getProjects();
    },[])

    useEffect(() => {
        getPayments();
    },[])

    useEffect(() => {
        getFunds();
    }, [payments, elements])

    const handleSubmit = () => {
        console.log(date);
        let data = {type:type, element:element.id, means_of_payment:payment.id, name:name, amount:amount , 
            date: timestamp, project:project.id}
        axiosInstance.put(`/cash/cashfunds/${id}/`, data).then((response) => {
            let result = response.data
            setMessage(result.message);
            setVisible(true);
        })
    }

    const handleDate = (value) => {
        let new_ts = parseInt((new Date(value.split("T")[0]).getTime() / 1000).toFixed(0) - 1) 
        setDate(value);
        setTimestamp(new_ts);
    }

    return (
        <Form onSubmit={(value) => handleSubmit()}> 
            {visible && (<Layer
                onEsc={() => setVisible(false)}
                onClickOutside={() => setVisible(false)}
                margin="medium"
                style={{padding: "2%"}}
              >
              <Text>{message}</Text>
                <Box direction="row"  pad="xsmall" alignSelf="end" >
                  <Anchor label="Fermer" color="primary" align='start' onClick={() => setVisible(false)} />
                </Box>
              </Layer>)}
            <FormField name="date" htmlFor="textinputDate" label="Date" margin="small" width="50%">
                <DateInput value={date} id="textinputAmount" required name="date" onChange={(event) => handleDate(event.value)} format="dd/mm/yyyy"/>
            </FormField>
           <FormField name="name" htmlFor="textinputName" label="Objet" margin="small" width="50%">
                <TextInput id="textinputName" value={name} required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <FormField name="amount" htmlFor="textinputName" label="Montant" margin="small" width="50%">
                <TextInput id="textinputAmount" value={amount} required name="amount" onChange={(event) => setAmount(event.target.value)} type="number"/>
            </FormField>
            <FormField name="elements" htmlFor="elements" label="Elements" width="50%" margin="small">
                <Select options={elements} value={element} onChange={({option}) => setElement(option) } labelKey={(opt) => getElementName(opt)} required/>
            </FormField>
            <FormField name="payment" htmlFor="payment" label="Moyen de Paiements" width="50%" margin="small">
                <Select options={payments} value={payment} onChange={({option}) => setPayment(option) } labelKey={(opt) => getElementName(opt)} required/>
            </FormField>
            <FormField name="projects" htmlFor="projects" label="Projets" width="50%" margin="small">
                <Select options={projects} value={project} onChange={({option}) => setProject(option) } labelKey="name" required/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Modifier" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default EditFunds; 