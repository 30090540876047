import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Layer, Text, Anchor} from 'grommet';
import { useState } from 'react';
import axiosInstance from '../../../AxiosInstance';
import Breadcrumb from '../../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const AddProject = () => {
    const elements = [{'label': 'Paramètres', 'link': '/Settings'}, {'label': 'Nouveau projet', 'link': '/AddProject'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Ajout d'un journal</CardHeader>
                    <CardBody pad="small" height="medium" flex><ProjectForm /></CardBody>
                </Card>    
            </Box> 
        </Box>       
    );
}

const ProjectForm = () => {
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [visible, setVisible] = useState(false);


    const handleSubmit = () => {
        axiosInstance.post("/project/projects/", {"name": name}).then((response) => {
            setMessage(response.data.message);
            setVisible(true);
        })
    }

    return (
        <Form onSubmit={(value) => handleSubmit()}> 
            {visible && (<Layer
                onEsc={() => setVisible(false)}
                onClickOutside={() => setVisible(false)}
                margin="medium"
                style={{padding: "2%"}}
              >
              <Text>{message}</Text>
                <Box direction="row"  pad="xsmall" alignSelf="end" >
                  <Anchor label="Fermer" color="primary" align='start' onClick={() => setVisible(false)} />
                </Box>
              </Layer>)}

            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Ajouter" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default AddProject; 