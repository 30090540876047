export const TYPE_ELEMENT = 1
export const TYPE_MEANS_OF_PAYMENT = 2
export const INFLOW = 1
export const OUTFLOW = 2
export const TYPE_STOCK = 1
export const TYPE_DEBT = 2
export const TYPE_CLAIM = 3
export const TYPE_AMORTIZATION = 4
export const BLOTTER_TYPE_BANQUE = 100
export const BLOTTER_TYPE_CASH = 200
