import React from 'react';
import { Box } from 'grommet';

    
function Main(){
    return (
   
        <Box flex align='center' justify='center'>
              
        </Box>
    )
}

export default Main;  