import { useState, useEffect } from "react";
import { Text, Box, Button, DataTable, Layer, Notification } from "grommet";
import { Add } from "grommet-icons";
import axiosInstance from "../../../AxiosInstance";
import { useNavigate } from "react-router-dom";
import { TYPE_MEANS_OF_PAYMENT } from "../../../Params";
import { EditButton, DeleteButton } from "../../Custom/CustomButton";

const MOP = () => {
    const [data, setData] = useState([]); 
    const navigate = useNavigate();
    const [select, setSelect] = useState(0);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(""); 
    const [apiMessage, setApiMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    let columns = [
        {
            property: 'name',
            header: <Text>Nom</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'account_name',
            header: <Text>Compte</Text>,
            sortable : true,
            search: true, 
        },
        {
            property: 'action',
            header: <Text>Actions</Text>,
            
            render : datum => (
                <Box direction="row" justify="between"  pad="xsmall">
                    <EditButton id={datum.id} handleClick={handleEdit}/>  
                    <DeleteButton id={datum.id} confirm={confirmDelete} /> 
                </Box>    
             ) , 
        },
        
    ]

    const getAccounts = () => {
        axiosInstance.get("/cash/elements/?type="+TYPE_MEANS_OF_PAYMENT, data).then(result => {
            setData(result.data);
        });
    }

    const handleEdit = (id) => {
        navigate("/EditElement/"+TYPE_MEANS_OF_PAYMENT+"/"+id);
    }

    const confirmDelete = (id) => {
        setSelect(id);
        setMessage("Voulez-vous vraiment supprimer ce moyen de paiement ?")
        setVisible(true);
    }

    const handleDelete = () => {
        axiosInstance.delete(`/cash/elements/${select}/`).then((response) => {
            setApiMessage(response.data.message)
        });
        getAccounts();
        setVisible(false);
        setShowError(true); 
    }

    useEffect(() =>{
        getAccounts();
    }, [])
    

    return (
        
        <Box direction="column" pad="small" justify="between">
            {showError && (
            <Notification
                title="Notification"
                message={apiMessage}
                status="warning"
                onClose={() => {setShowError(false)}}
            />)}
            <Box direction="row">
                <Box direction="column">
                    <Button type="button" label="Ajouter" color="primary" icon={<Add />} margin="xsmall" 
                    onClick={() => navigate(`/AddElement/${TYPE_MEANS_OF_PAYMENT}`)} />
                </Box> 
            </Box>
            <Box  direction="column" width="xlarge" margin={{top: "2%"}}>
             <DataTable 
                columns={columns} 
                data={data} 
                paginate={true}
                sortable = {true}
                step={5}
                margin="xxsmall"
                background={{header:"bluetable", body:["white", "bluetable"]}}
                pad="small"
                />
            </Box>
            {visible && (
                <Layer
                    onEsc={() => setVisible(false)}
                    onClickOutside={() => setVisible(false)}
                    margin="medium"
                    style={{padding: "2%"}}
                >
                    <Text>{message}</Text>
                    <Box direction="row"  fill justify="between" pad="small">
                        <Button label="Fermer" color="light-1" onClick={() => setVisible(false)} style={{width: "40%"}} />
                        <Button label="Accepter" color="primary" onClick={() => handleDelete()} style={{width: "40%"}} />
                    </Box>
                </Layer>
      )}
        </Box>
        
    )
}

export default MOP;