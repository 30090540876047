import React from 'react';
import { TYPE_CLAIM, TYPE_DEBT } from "../../Params";
import { Layer, Anchor, FileInput, Box, Button, Text } from "grommet";
import axiosInstance from "../../AxiosInstance";
import { useState } from "react";
import { Trash, Edit, Validate, Upload } from "grommet-icons"
import { Tip } from "grommet"
import { useEffect } from 'react';

export const DeleteButton = (props) => {
    let {id, confirm} = props
    return (<Tip content="Supprimer"><Trash color="primary" onClick={() => confirm(id)}/></Tip> )
}

export const EditButton = (props) => {
    let {id, handleClick} = props
    return (<Tip content="Editer"><Edit color="primary" onClick={() =>handleClick(id)}/></Tip> )
}

export const ValidateButton = (props) => {
    let {id, handleClick} = props
    return (<Tip content="Valider"><Validate color="neutral-1" onClick={() =>handleClick(id)}/></Tip> )
}

export const UploadButton = (props) => {

    const [showLayer, setShowLayer] = useState(false);
    const [files , setFiles] = useState([]);
    const [ message, setMessage] = useState("")

    let {id, type, source} = props;
    
    const handleUpload = () => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i += 1) {
            formData.append(`files[${i}]`,files[i]);
        }
        formData.append("file_number", files.length);
        formData.append("source",source); 
        axiosInstance.put(`/edm/cash_document/${id}/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }}).then((response) => {
                setMessage(response.data.message)
        });
    }

    if(((type === TYPE_DEBT || type === TYPE_CLAIM) && source === "eval") || source === "funds"){
        return (<><Tip content="Charger fichier">
                    <Upload color="primary" style={{marginLeft:"15%"}} onClick={() => setShowLayer(true) }/>
                </Tip> 
                {showLayer &&(
                <Layer
                    onEsc={() => setShowLayer(false)}
                    onClickOutside={() => setShowLayer(false)}
                    margin="medium"
                    style={{padding: "2%"}}
                >
                    {message ? message : <><FileInput id="textFile" 
                        onChange={(event) => {setFiles(event.target.files)}}
                        multiple 
                        messages={{browse:"Ajouter", dropPromptMultiple:"Faites glisser les fichiers ici"}}/>
                        <Box direction="row"  pad="xxsmall">
                            <Anchor label="Fermer" color="light-4" onClick={() => setShowLayer(false)} style={{width: "40%"}} />
                            <Anchor target="blank"  label="Ajouter" color="primary" primary style={{width: "40%"}} onClick={() => handleUpload()} />
                        </Box></>}
                </Layer>)}
               </> )
    }
    else{
        return (<></>)
    }
    
}

export const DownloadButton = (props) => {
    const {url, label, ...params} = props;
    const [disabled, setDisabled] = useState(true);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState("");
    const [link, setLink] = useState("");
    

    const handleStatement = () => {
        setMessage("Votre document est en cours de téléchargement...");
        setVisible(true)
        axiosInstance.post(url, params).then((result) => {
          if (result.data.code === 200){
            setLink(result.data.path)
            setDisabled(false)
          }
          setMessage(result.data.message);
          
        });
      }
    
    
    return (
        <>
            <Button label={label} color="primary" onClick={() => handleStatement()}  margin="xsmall" />
            {visible && (
            <Layer
                    onEsc={() => setVisible(false)}
                    onClickOutside={() => setVisible(false)}
                    margin="medium"
                    style={{padding: "2%"}}
                >
                <Text>{message}</Text>
                <Box direction="row"  fill justify="between" pad="small">
                    <Anchor label="Fermer" color="light-4" onClick={() => setVisible(false)} style={{width: "40%"}} disabled={disabled}/>
                    <Anchor href={link} target="blank" disabled={disabled} label="Télécharger"  color="primary" primary style={{width: "40%"}} />
                </Box>
            </Layer>)}
        </>    
    )

}



