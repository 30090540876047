import React from 'react';
import { CustomCard } from "../Custom/CustomCard";
import { Box } from "grommet";
import { OUTFLOW } from "../../Params";
import { CashContent } from "./Cashin";
import Breadcrumb from "../Custom/Breadcrumb";

function Cashout(){
    const elements = [{'label': 'Sortie de fonds', 'link': '/Cashout'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box flex align='center' justify='between' pad="medium" direction="column">
                <CustomCard title="Sorties de fonds" content={<CashContent type={OUTFLOW}/>} />
            </Box>
        </Box>
    );
}


export default Cashout;