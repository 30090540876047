
import React from 'react';
import { Box, Anchor } from "grommet";
import { useEffect, useState } from "react";

const Breadcrumb = (props) => {
    const [data, setData] = useState([])

    const elements = props.elements;
    useEffect(() => {
        let result = []
        elements.map((element, index) => {
            if (index !== 0){
                result.push(<Anchor key={-index} label=' -> ' margin="xsmall" color="primary" />)
            }
            result.push(<Anchor  key={index} margin="xsmall" color="primary" label={element.label} href={element.link}/>)
            
        });
        setData(result)

    }, [])


    return (
        <Box direction="row" pad="small" margin={{left:"medium",top:"medium", right:"medium"}} background='light-1'  align='left'> 
            {data.map(res => res)}
        </Box>
    )
}

export default Breadcrumb;