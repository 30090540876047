import React from 'react';
import { Box, Tab, Tabs } from "grommet"
import { CustomCard } from "../Custom/CustomCard";
import General from "./General";
import Account from "./Account/Account"; 
import Journal from "./Journal/Journal";
import Elements from "./Elements/Elements";
import MOP from "./Elements/MOP";
import Breadcrumb from "../Custom/Breadcrumb";
import ListProject from "./Project/ListProject";

const Settings = () => {
    const elements = [{'label': 'Paramètres', 'link': '/Settings'}]
    return (
        <>
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/>  
    
            <Box pad="medium" direction="row" fill>
                <Tabs style={{color: "primary"}}  align="center" >
                    <Tab title="Général">
                        <CustomCard title="Paramètres" content={<General />}/>
                    </Tab>
                    {/* <Tab title="Utilisateurs">
                        <CustomCard title="Utilisateurs" content={<ListUsers />}/>
                    </Tab>*/}
                    <Tab title="Comptes">
                        <CustomCard title="Comptes" content={<Account />}/>
                    </Tab>
                    <Tab title="Journaux">
                        <CustomCard title="Journaux" content={<Journal />}/>
                    </Tab>
                    <Tab title="Élèments">
                        <CustomCard title="Élèments" content={<Elements />}/>
                    </Tab>
                    <Tab title="Moyens de paiement">
                        <CustomCard title="Moyens de paiement" content={<MOP />}/>
                    </Tab>
                    <Tab title="Projets">
                        <CustomCard title="Projets" content={<ListProject />}/>
                    </Tab>
                </Tabs>
                </Box>
            </Box>  
        </> 
    )
}


export default Settings;

