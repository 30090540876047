import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Text, Notification} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../AxiosInstance';
import Breadcrumb from '../../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const EditAccount = () => {
    let {id} = useParams();
    const elements = [{'label': 'Paramètres', 'link': '/Settings'}, {'label': 'Edition de compte', 'link': '/EditAccount/'+id}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Editer un compte</CardHeader>
                    <CardBody pad="small" height="medium" flex><AccountForm /></CardBody>
                </Card>
            </Box> 
        </Box>       
);
}

const AccountForm = () => {
    const [code, setCode]  = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    let {id} = useParams()
    

    const getAccount = () => {
        axiosInstance.get("/api/account/?id="+id).then(result => {
            if (result.data){
                let data = result.data[0];
                setCode(data.code);
                setName(data.nom);
            }
        });
    }

    useEffect(() => {
        getAccount();
    }, [])

    const handleSubmit = () => {
        axiosInstance.put(`/api/account/${id}/`, {"nom": name, "code": code}).then((response) => {
            let result = response.data
            if(result.code == 200){
                navigate("/Settings")
            }
            else{
                setMessage(result.message);
                setVisible(true);
            }
        })
    }

    return (
        <Form onSubmit={(value) => handleSubmit()} > 
            {visible && (<Notification
                title="Erreur"
                message={message}
                onClose={() => {setVisible(false)}}
                status = "critical"
                style={{backgroundColor: "neutral-4", "color":"black"}}
            />)}
            
            <FormField name="code" htmlFor="textinputCode" label="Code" margin="small" width="50%">
                <TextInput id="textinputCode" name="code" value={code} required onChange={(event) => setCode(event.target.value)}/>
            </FormField>
            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required value={name} name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Modifier" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default EditAccount; 