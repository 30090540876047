import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Select, Notification} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../AxiosInstance';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const EditAccountConfig = () => {
   

    return (
       
        <Box align='center' pad="medium" direction="column" >
            <Card background="light-1" style={styles} width="large">
                <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Editer la configuration</CardHeader>
                <CardBody pad="small" height="medium" flex><AccountForm /></CardBody>
            </Card>
            
        </Box>     
    );
}

const AccountForm = () => {
    const [creditAccount, setCreditAccount]  = useState("");
    const [debitAccount, setDebitAccount]  = useState("");
    const [name, setName] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [journal, setJournal] = useState("");
    const [daccounts, setDAccounts] = useState([]); 
    const [caccounts, setCAccounts] = useState([]); 
    const [sjournal, setSJournal] = useState([]);
    const [cjournal, setCJournal] = useState([]); 
    const [message, setMessage] = useState("");
    const [visible, setVisible] = useState(false);

    let {id} = useParams()
    

    const getAccount = () => {
        axiosInstance.get("/api/account/").then(result => {
            setAccounts(result.data); 
            setCAccounts(result.data); 
            setDAccounts(result.data); 
            axiosInstance.get("/cash/evaluation_config/?id="+id).then(response =>{
                let data = response.data[0]
                setName(data.name)
                result.data.map((res) => {
                    if(res.id == data.credit_account){
                        setCreditAccount(res);
                    }
                    if(res.id == data.debit_account){
                        setDebitAccount(res)
                    }
                });
            })
        });
    }

    const getJournal = () => {
        axiosInstance.get("/api/journal/").then(result => {
            setCJournal(result.data);
            setSJournal(result.data);
            axiosInstance.get("/cash/evaluation_config/?id="+id).then(response =>{
                let data = response.data[0]
                result.data.map((res) => {
                    if(res.id == data.journal){
                        setJournal(res);
                    }
                });
            })
        });
    }

    useEffect(() => {
        getAccount();
    }, [])

    useEffect(() => {
        getJournal();
    }, [])

    const handleSubmit = () => {
        axiosInstance.put(`/cash/evaluation_config/${id}/`, {"name": name, "debit_account":debitAccount.id, "credit_account":creditAccount.id, 'journal': journal.id}).then((response) => {
            let result = response.data
            setMessage(result.message);
            setVisible(true);
        })
    }

    const getAccountName = (ac) => {
        return `${ac.code}-${ac.nom}`;
    }


    const handleDebitSearch = (e) => {
        if(e){
            let search = e.toLowerCase();
            let result = accounts.filter(element => {
                if (element.code.toLowerCase().includes(search) || element.nom.toLowerCase().includes(search)){
                    return element;
                }
            });
            setDAccounts(result);
        }
        else{
            setDAccounts(accounts)
        }
    }

    const handleCreditSearch = (e) => {
        if(e){
            let search = e.toLowerCase();
            let result = accounts.filter(element => {
                if (element.code.toLowerCase().includes(search) || element.nom.toLowerCase().includes(search)){
                    return element;
                }
            });
            setCAccounts(result);
        }
        else{
            setCAccounts(accounts)
        }
    }

    const handleJournalSearch = (e) => {
        if(e){
            let search = e.toLowerCase();
            let result = sjournal.filter(element => {
                if (element.code.toLowerCase().includes(search) || element.nom.toLowerCase().includes(search)){
                    return element;
                }
            });
            setSJournal(result);
        }
        else{
            setSJournal(cjournal);
        }
    }

    return (
        <Form onSubmit={(value) => handleSubmit()} > 
            {visible && (<Notification
                title="Erreur"
                message={message}
                onClose={() => {setVisible(false)}}
                status = "warning"
                style={{backgroundColor: "neutral-4", "color":"black"}}
            />)}
            
            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required value={name} name="name" readOnly/>
            </FormField>
            <FormField name="debit_account" htmlFor="debit_account" label="Compte Débit" width="50%" margin="small">
                <Select 
                    options={daccounts} 
                    value={debitAccount} 
                    onChange={({option}) => setDebitAccount(option) } 
                    labelKey={(opt) => getAccountName(opt)}
                    onSearch = {(e) => {handleDebitSearch(e)}}
                    required
                    />
            </FormField>
            <FormField name="credit_account" htmlFor="credit_account" label="Compte Crédit" width="50%" margin="small">
                <Select 
                    options={caccounts} 
                    value={creditAccount} 
                    onChange={({option}) => setCreditAccount(option) } 
                    labelKey={(opt) => getAccountName(opt)}
                    onSearch = {(e) => {handleCreditSearch(e)}}
                    required
                />
            </FormField>
            <FormField name="journal" htmlFor="journal" label="Journal" width="50%" margin="small">
                <Select 
                    options={sjournal} 
                    value={journal} 
                    onChange={({option}) => setJournal(option) } 
                    labelKey={(opt) => getAccountName(opt)}
                    onSearch = {(e) => {handleJournalSearch(e)}}
                    required
                />
            </FormField>
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Modifier" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default EditAccountConfig; 