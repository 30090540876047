import axios from "axios";


export const siteURL = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return "http://192.168.11.102:8001/"
    }
    else {
        return "https://iwo.bj";
    }
} 

const axiosInstance = axios.create({
    baseURL: siteURL(),
    headers: {
        "Authorization": "Token "+ localStorage.getItem("token"), 
        'Content-Type': 'application/json',
        'Accept' : 'application/json'
    },
});

//axiosInstance.interceptors.response.use((response) => response, (error) => {
//    console.log("error "+ error)
    // window.location.href = "/Login"
//});

export default axiosInstance;