import React from 'react';
import { CustomCard } from "../Custom/CustomCard";
import {  Box, Button, Text, DataTable,Notification,Layer } from "grommet";
import { INFLOW } from "../../Params";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../AxiosInstance";
import { Add } from "grommet-icons";
import { EditButton, DeleteButton, ValidateButton, UploadButton } from "../Custom/CustomButton";
import Breadcrumb from "../Custom/Breadcrumb";

function Cashin(){
    const elements = [{'label': 'Entrèe de fonds', 'link': '/Cashin'}]
    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/>  
            <Box fill  pad="medium" direction="column">
                <CustomCard title="Entrées de fonds" content={<CashContent type={INFLOW}/>} />
            </Box>
        </Box>    
    );
}


export const CashContent = (props) => {

    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [select, setSelect] = useState(0);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(""); 
    const [apiMessage, setApiMessage] = useState("");
    const [showError, setShowError] = useState(false); 

    let {type, id}=props 
    

    const getData = () => {
        axiosInstance.get("/cash/cashfunds/?type="+type).then(result => {
            setData(result.data.data);
        });
    }

    const handleEdit = (id) => {
        navigate("/EditFunds/"+type+"/"+id)
    }

    useEffect(() => {
        getData();
    },[])

    const confirmDelete = (id) => {
        setSelect(id); 
        setMessage("Voulez-vous vraiment supprimer cette ligne ?")
        setVisible(true);
    }

    const handleDelete = () => {
        axiosInstance.delete(`/cash/cashfunds/${select}/`).then((response) => {
            setApiMessage(response.data.message)
        });
        setVisible(false);
        setShowError(true); 
        getData();
    }

    const handleValidate = (id) => {
        axiosInstance.post('/cash/validate_funds/', {funds_id: id}).then((response) => {
            setApiMessage(response.data.message)
            setShowError(true); 
            getData();
        });
    }
    
    let columns = [
        {
            property: 'fund_date',
            header: <Text>Date</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
          property: 'name',
          header: <Text>Objet</Text>,
          primary: true,
          sortable : true,
          search: true,
        },

        {
            property: 'project_name',
            header: <Text>Projet</Text>,
            primary: false,
            sortable : true,
            search: true,
          },

        {
            property: 'element_name',
            header: <Text>Element</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
            property: 'means_of_payment_name',
            header: <Text>Paiement</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        
        {
            property: 'amount',
            header: <Text>Montant</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
            property: 'operation_status',
            header: <Text>Statut</Text>,
            primary: false,
            sortable : true,
            search: true, 
        },
        {
            property: 'action',
            header: <Text>Actions</Text>,
            
            render : datum => (
                <Box direction="row" justify="between"  pad="xsmall">
                    {datum.can_edit ? <EditButton id={datum.id} handleClick={handleEdit}/> :<> </> }
                    {datum.operation_status === 'Brouillon' ? <><ValidateButton id={datum.id} handleClick={handleValidate} /></>: <></>}
                    {datum.can_delete ? <DeleteButton id={datum.id} confirm={confirmDelete} />  : <></>}
                    {datum.can_edit ? <UploadButton id={datum.id} type={datum.type} source="funds"/> : <></> }
                </Box>    
             ) , 
        },
    ]


    return (<Box  pad="xsmall" direction="column">
    <Box direction="column" pad="xsmall" justify="between">
    {showError && (
    <Notification
        title="Notification"
        message={apiMessage}
        status="warning"
        onClose={() => {setShowError(false)}}
    />)}
    <Box direction="row">
        <Box direction="column" >
            <Button type="button" label="Ajouter" color="primary" icon={<Add />} margin="xsmall" 
                onClick={() => navigate(`/AddFunds/${type}`)} />
        </Box>
    </Box>
    <Box  direction="column" width="xlarge" margin={{top: "2%"}} fill>
        <DataTable 
            columns={columns} 
            data={data} 
            paginate={true}
            sortable = {true}
            step={5}
            margin="xxsmall"
            background={{header:"bluetable", body:["white", "bluetable"]}}
            pad="small"
        />
    </Box>
    {visible && (
        <Layer
            onEsc={() => setVisible(false)}
            onClickOutside={() => setVisible(false)}
            margin="medium"
            style={{padding: "2%"}}
        >
            <Text>{message}</Text>
            <Box direction="row"  fill justify="between" pad="small">
                <Button label="Fermer" color="light-1" onClick={() => setVisible(false)} style={{width: "40%"}} />
                <Button label="Accepter" color="primary" onClick={() => handleDelete()} style={{width: "40%"}} />
            </Box>
        </Layer>
    )}
    </Box>
</Box>)
}


export default Cashin;