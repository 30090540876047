import React from 'react';
import { Anchor, Box, Heading, Layer, Text } from "grommet";
import { Currency, DocumentCloud, DocumentPerformance, Download, FormAdd, FormSubtract, Money, SettingsOption, Storage } from "grommet-icons";
import axiosInstance from "../AxiosInstance";
import { useState } from "react";

const Navbar = () => {
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  
  const handleStatement = () => {
    setMessage("Votre document est en cours de téléchargement...");
    setVisible(true)
    axiosInstance.get("/api/financial_statement/").then((result) => {
     
      if (result.data.code === 200){
        setLink(result.data.path)
        setDisabled(false)
      }
      setMessage(result.data.message);
      
    });
  }

    return (
        <Box width='small' background='light-1' elevation='small' pad="small" style={{fontSize:'12px'}}>
          <Anchor href="/Settings" label="Paramètres" icon={<SettingsOption color="primary"/>} color="dark-4" margin="small"/>
          <Anchor href="/Cashin" label="Entrées de fonds" icon={<Currency color="primary"/>} color="dark-4" margin="small"/> 
          <Anchor href="/Cashout" label="Sorties de fonds" icon={<Currency color="primary"/>} color="dark-4" margin="small"/> 
          <Anchor href="/Blotter" label="Brouillard" icon={<DocumentPerformance color="primary"/>} color="dark-4" margin="small"/> 
          <Heading level="3" color="primary" textAlign="center">Evaluation</Heading>
          <Anchor href="/Amortization" label="Amortissements" icon={<Money color="primary"/>} color="dark-4" margin="small"/>
          <Anchor href="/Stock" label="Stock" icon={<Storage color="primary"/>} color="dark-4" margin="small"/>
          <Anchor href="/Dettes" label="Dettes" icon={<FormSubtract color="primary"/>} color="dark-4" margin="small"/> 
          <Anchor href="/Creances" label="Créances" icon={<FormAdd color="primary"/>} color="dark-4" margin="small"/> 
          <Anchor onClick={() => handleStatement()} label="Etat financier" icon={<Download color="primary"/>} color="dark-4" margin="small"/> 
          {/*<Anchor href="/Cashin" label="Factures" icon={<DocumentText color="primary"/>} color="dark-4" margin="small"/> */}
          <Anchor href="/File" label="Documents" icon={<DocumentCloud color="primary"/>} color="dark-4" margin="small"/> 
      
          {visible && (
             <Layer
                onEsc={() => setVisible(false)}
                onClickOutside={() => setVisible(false)}
                margin="medium"
                style={{padding: "2%"}}
              >
              <Text>{message}</Text>
                <Box direction="row"  fill justify="between" pad="small">
                  <Anchor label="Fermer" color="light-4" onClick={() => setVisible(false)} style={{width: "40%"}} disabled={disabled}/>
                  <Anchor href={link} target="blank" disabled={disabled} label="Télécharger" color="primary" primary style={{width: "40%"}} />
                </Box>
              </Layer>
      )}

        </Box>

    );
}

export default Navbar; 