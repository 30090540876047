import React from 'react';
import {Box, FileInput, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, DateInput, Layer, Text, Anchor} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../AxiosInstance';
import { TYPE_STOCK, TYPE_DEBT, TYPE_CLAIM, TYPE_AMORTIZATION } from '../../Params';
import Breadcrumb from '../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const AddEvaluation = () => {
    let {type} = useParams()
    const [label, setLabel] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
        let value = [];
        switch(parseInt(type)){
            case TYPE_STOCK:
                setLabel("Ajout d'un stock");
                value = [{'label': 'Stock', 'link': '/Stock'},{'label': "Ajout d'un stock", 'link': '/AddEvaluation/'+type}]
                break;
            case TYPE_DEBT:
                setLabel("Ajout d'une dette");
                value = [{'label': 'Dettes', 'link': '/Dettes'},{'label': "Ajout d'une dette", 'link': '/AddEvaluation/'+type}]
                break;
            case TYPE_CLAIM:
                setLabel("Ajout d'une créance");
                value = [{'label': 'Créances', 'link': '/Creances'},{'label': "Ajout d'une créance", 'link': '/AddEvaluation/'+type}]
                break;
            case TYPE_AMORTIZATION:
                setLabel("Ajout d'un amortissement");
                value = [{'label': 'Amortissement', 'link': '/Amortization'},{'label': "Ajout d'un amortissement", 'link': '/AddEvaluation/'+type}]
                break;
            default:
                setLabel("Undefined");
        }
        setTitle(<Breadcrumb elements={value}/>)
    }, []); 

   

    return (
        <Box direction="column" fill>
            {title}
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>{label}</CardHeader>
                    <CardBody pad="small" height="medium" flex overflow="auto"><EvaluationForm /></CardBody>
                </Card>    
            </Box> 
        </Box>    
    );
}

const EvaluationForm = () => {
    const [date, setDate] = useState(new Date().toISOString()); 
    const [amount, setAmount] = useState([]);
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    const [showFile, setShowfile] = useState(false);
    const [files , setFiles] = useState([]);
    let {type} = useParams()

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('type', type);
        formData.append('date', parseInt((new Date(date.split("T")[0]).getTime() / 1000).toFixed(0)));
        formData.append('name', name);
        formData.append('amount', amount);
        for (let i = 0; i < files.length; i += 1) {
            formData.append(`files[${i}]`,files[i]);
        }
        formData.append("file_number", files.length)
        // let data = {type:type, date:parseInt((new Date(date).getTime() / 1000).toFixed(0) ), name:name, amount:amount }
        axiosInstance.post("/cash/evaluation/", formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }}).then((response) => {
            setMessage(response.data.message);
            setVisible(true);
            
        })
    }

    useEffect(() => {
        let type_evaluation = parseInt(type);
        if(type_evaluation === TYPE_DEBT || type_evaluation === TYPE_CLAIM ){
            setShowfile(true);
        }
    },[type])

    const handleDate = (value) => {
        setDate(value);
    }

    return (
        <Form onSubmit={(value) => handleSubmit()}> 
            {visible && (<Layer
                onEsc={() => setVisible(false)}
                onClickOutside={() => setVisible(false)}
                margin="medium"
                style={{padding: "2%"}}
              >
              <Text>{message}</Text>
                <Box direction="row"  pad="xsmall" alignSelf="end" >
                  <Anchor label="Fermer" color="primary" align='start' onClick={() => setVisible(false)} />
                </Box>
              </Layer>)}
            <FormField name="date" htmlFor="textinputDate" label="Date" margin="small" width="50%">
                <DateInput value={date} id="textinputAmount" required name="date" onChange={(event) => handleDate(event.value)} format="dd/mm/yyyy"/>
            </FormField>
            <FormField name="name" htmlFor="textinputName" label="Objet" margin="small" width="50%">
                <TextInput id="textinputName" required name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            <FormField name="amount" htmlFor="textinputName" label="Montant" margin="small" width="50%">
                <TextInput id="textinputAmount" required name="amount" onChange={(event) => setAmount(event.target.value)} type="number"/>
            </FormField>
            
            {showFile &&(
                <FormField name="file" htmlFor="textFile" label="Justificatif" margin="small" width="50%">
                    <FileInput id="textFile" 
                    onChange={(event) => {setFiles(event.target.files)}}
                    multiple 
                    messages={{browse:"Ajouter", dropPromptMultiple:"Faites glisser les fichiers ici"}}/>
                </FormField>
            )}
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Ajouter" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default AddEvaluation; 
