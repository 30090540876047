import React from 'react';
import {Box, Form, FormField, TextInput, Button,Card,CardHeader, CardBody, Select, Layer, Anchor, Text} from 'grommet';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../AxiosInstance';
import Breadcrumb from '../../Custom/Breadcrumb';

const styles = {
    borderRadius:'0px',
    marginTop: '1px',
}   

const EditProject = () => {
    let {id} = useParams()
    const elements = [{'label': 'Paramètres', 'link': '/Settings'}, {'label': 'Edition projet', 'link': '/EditProject/'+id}]

    return (
        <Box direction="column" fill>
            <Breadcrumb elements={elements}/> 
            <Box pad="medium" direction="column" >
                <Card background="light-1" style={styles} width="large">
                    <CardHeader pad="small" background="primary" style={{fontWeight:"Bold"}}>Edition d'un journal</CardHeader>
                    <CardBody pad="small" height="medium" flex><ProjectForm /></CardBody>
                </Card>
            </Box>
        </Box>        
                
    );
}

const ProjectForm = () => {
    
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [visible, setVisible] = useState(false);
    
    const navigate = useNavigate();

    let {id} = useParams()

    const getProject = () => {    
        axiosInstance.get("/project/projects/?id="+id).then(result => {
        if (result.data){
            let data = result.data[0];
            setName(data.name)
                    
        }
    });
       
}

   

    useEffect(() => {
        getProject();
    }, [])

    



    const handleSubmit = () => {
        axiosInstance.put(`/project/projects/${id}/`, {"name": name}).then((response) => {
            let result = response.data
            setMessage(result.message);
            setVisible(true); 
        });
    }

    return (
        <Form onSubmit={(value) => handleSubmit()} > 
            {visible && (<Layer
                onEsc={() => setVisible(false)}
                onClickOutside={() => setVisible(false)}
                margin="medium"
                style={{padding: "2%"}}
              >
              <Text>{message}</Text>
                <Box direction="row"  pad="xsmall" alignSelf="end" >
                  <Anchor label="Fermer" color="primary" align='start' onClick={() => setVisible(false)} />
                </Box>
              </Layer>)}
            
            
            <FormField name="name" htmlFor="textinputName" label="Nom" margin="small" width="50%">
                <TextInput id="textinputName" required value={name} name="name" onChange={(event) => setName(event.target.value)}/>
            </FormField>
            
            <Box direction="column" gap="medium">
                <Box direction="row" gap="xlarge" justify='between'>
                    <Button type="submit" margin="small" label="Modifier" color="primary" style={{width: "25%"}}/>            
                </Box>
            </Box>
        </Form>
    )
}

export default EditProject; 